<template>
  <el-pagination
    class="cpt-table-pagination"
    :class="`${size}`"
    @size-change="(e) => $emit('size-change', e)"
    @current-change="(e) => $emit('page-change', e)"
    :current-page="currentPage || 1"
    :page-sizes="pageSizes || [5, 10, 50, 100]"
    :page-size="pageSize || 10"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total || 0"
  />
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSizes: {
      type: Array,
      default: () => [],
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: () => "middle",
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-table-pagination {
  height:50px;
  display: flex;
  // align-items: center;
  ::v-deep {
    button {
      background: transparent;
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      border: 1px solid #B6D4FF;
      font-size: 18px;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      border-radius: 4px;
      line-height: 40px;
      margin-right: 8px;
      box-sizing: border-box;
      color: #9fc4ff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      &:disabled {
        background: transparent;
        color: #889fb2;
        opacity: 0.3;
      }
    }
    .el-pagination__total {
      font-size: 18px;
      color: #93b6f0;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;

      text-align: center;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      margin: 0px;
    }
    .el-pagination__sizes {
      height: 40px;
      line-height: 40px;
      margin: 0px;
      box-sizing: border-box;
      border: 1px solid #B6D4FF;
      border-radius: 4px;
      margin: 0 6px;
      .el-select {
        .el-input {
          width: 100px;
          padding-right: 20px;
          margin: 0;
          .el-input__inner {
            background: transparent;
            border: none;
            font-size: 18px;
            color: #93b6f0;
            text-align: center;
            font-weight: 400;
            padding: 0px;
            height: 40px;
          }
          .el-input__suffix {
            .el-input__suffix-inner {
              .el-icon-arrow-up {
                line-height: 42px;
              }
            }
          }
        }
      }
    }
    .el-pager {
      height:30px;
      .number {
        background: transparent;
        font-size: 12px;
        color: #9fc4ff;
        text-align: center;
        font-weight: lighter;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        border: 1px solid #B6D4FF;
        font-size: 18px;
        letter-spacing: 0;
        text-align: center;
        border-radius: 4px;
        line-height: 40px;
        margin-right: 8px;
        box-sizing: border-box;
        &.active {
          opacity: 0.8;
          background-image: linear-gradient(
            180deg,
            rgba(44, 123, 255, 0.5) 0%,
            rgba(72, 140, 255, 0) 100%
          );
          color: #ffffff;
        }
      }
      .more {
        background: transparent;
        line-height: 40px;
        width: 40px;
        height: 40px;

        box-sizing: border-box;
        border: 1px solid #B6D4FF;
        border-radius: 4px;
        margin-right: 8px;
        font-size: 20px;
        color: #93b6f0;
      }
    }
    .el-pagination__jump {
      font-size: 18px;
      color: #90b2ec;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      height: 40px;
      height: 40px;
      margin-left: 6px;
      line-height: 40px;
      display: flex;
      align-items: center;

      .el-input {
        box-sizing: border-box;
        height: 100%;
        .el-input__inner {
          border: 1px solid #B6D4FF;
          border-radius: 4px;
          background: transparent;
          height: 100%;
          box-sizing: border-box;
          padding: 0;

          font-size: 18px;
          color: #90b2ec;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
        }
      }
    }
  }

  &.mini {
    height: 100%;
    display: flex;
    align-items: center;
    ::v-deep {
      button {
        height: 20px;
        width: 20px;
        line-height: 20px;
        background: transparent;
        border: none;
        color: #4d7592;
        min-width: auto;
        &.btn-next {
          margin-left: 8px;
        }
      }
      .el-pagination__total {
        font-size: 14px;
        color: #4d7592;
      }
      .el-pagination__sizes {
        display: none;
      }

      .el-pager {
        height:30px;

        .number {
          height: 100%;
          width: auto;
          line-height: 20px;
          background: transparent;
          border: none;
          font-size: 14px;
          color: #4d7592;
          letter-spacing: 0;
          text-align: center;
          font-weight: 500;
          min-width: 20px;
          margin-right: 0;
          &.active {
            opacity: 1;
            color: #0099ff;
          }
        }
        .more {
          height: 100%;
          width: 20px;
          line-height: 20px;
          font-size: 12px;
          border: none;
          min-width: auto;
          margin-right: 0px;
          &::before {
            line-height: 20px;
          }
        }
      }

      .el-pagination__jump {
        height: 100%;
        font-size: 14px;
        color: #4d7592;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;

        .el-input {
          .el-input__inner {
            border: 1px solid #B6D4FF;
            border-radius: 2px;
            width: auto;
            min-width: 20px;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>