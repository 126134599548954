<template>
  <div class="cpt-service-table" :class="`${size}`">
    <div class="table-hd">
      <div class="divider top"></div>
      <div class="table-tr">
        <div
          class="table-th"
          v-for="column in columns"
          :key="column.prop"
          :style="`${column.width ? `width:${column.width}` : `flex: 1`}`"
        >
          {{ column.label }}
        </div>
      </div>
      <div class="divider bottom"></div>
    </div>
    <div class="table-bd" @scroll="onScroll($event)">
      <div class="table-tr" v-for="item in dataSource" :key="item.id">
        <div
          class="table-td"
          v-for="(column, index) in columns"
          :key="column.prop"
          :style="`${column.width ? `width:${column.width}` : `flex: 1`}`"
        >
          <template v-if="column.slot">
            <slot :name="column.prop" :row="item"></slot>
          </template>
          <div v-else class="table-td-content">
            <template v-if="item[column.prop]">
              <el-tooltip
                effect="dark"
                :open-delay="300"
                :content="
                  index == 8
                    ? `${item.origin} — ${item.destination}`
                    : `${item[column.prop]}`
                "
                placement="top"
                popper-class="my-tooltip"
              >
                <div class="content-inner">
                  {{
                    index == 8
                      ? `${item.origin} — ${item.destination}`
                      : `${item[column.prop]}`
                  }}
                </div>
              </el-tooltip>
            </template>
            <template v-else>
              <div class="content-inner">{{ column.id == 1 ? 0 : "暂无" }}</div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="table-pagination" v-if="pagination">
      <Pagination
        @size-change="(e) => $emit('size-change', e)"
        @page-change="(e) => $emit('page-change', e)"
        :current-page="pagination.page || 1"
        :page-sizes="pagination.pageSizes || [5, 10, 50, 100]"
        :page-size="pagination.pageSize || 10"
        :total="pagination.total || 0"
        :size="size"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/common/pagination";
import API from "@/api";
import methods from "../../fiexdright/methods";
import { number } from "echarts";
export default {
  props: {
    columns: {
      type: Array,
      default: [],
    },
    dataSource: {
      type: Array,
      default: [],
    },
    pagination: {
      type: Object,
      default: () => null,
    },
    size: {
      type: String|Number,
      default: () => "middle",
    },
  },
  components: { Pagination },
  methods: {
    // 监听鼠标是否滚动，滚动就隐藏掉el-tooltip
    onScroll(e) {
      let list = document.getElementsByClassName("el-tooltip__popper");
      if (list.length > 0) {
        list[list.length - 1].style.display = "none";
      }
    },
  },
};
</script>
<style lang="scss">
// el-tooltip的样式不能放在scoped中，原因：动态添加组件的层级是和app是在同一层级的
.my-tooltip {
  z-index: 9999 !important;
}
</style>
<style lang="scss" scoped>
.divider {
  height: 1px;
  background: rgba(148, 185, 243, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 8px;
    height: 2px;
    background-color: #ef985a;
  }
  &::after {
    content: " ";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 8px;
    height: 2px;
    background-color: #ef985a;
  }
  &.top {
    top: 0;
  }
  &.bottom {
    bottom: 0;
  }
}

.cpt-service-table {
  ::-webkit-scrollbar {
    width: 5px; /*滚动条宽度*/
    height: 16px; /*滚动条高度*/
  }
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .table-hd {
    width: 100%;
    position: relative;
    height: 52px;
    display: flex;
    box-sizing: border-box;
    flex-shrink: 0;
    .table-tr {
      width: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      .table-th {
        text-align: center;
        box-sizing: border-box;
        font-size: 16px;
        color: #9fc4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 500;
      }
    }
  }
  .table-bd {
    flex: 1;
    overflow: auto;
    overflow-x: hidden;
    margin-bottom: 30px;
    .table-tr {
      height: 52px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      &:nth-of-type(even) {
        background: rgba(0, 129, 255, 0.15);
      }
      .table-td {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
        box-sizing: border-box;
        font-size: 14px;
        color: #9BBFF8;
        letter-spacing: 0;
        text-align: center;
        line-height: 16px;
        font-weight: 400;

        .table-td-content {
          width: 100%;
          text-align: center;
          .content-inner {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
          }
        }
      }
    }
  }
  .table-pagination {
    height: 40px;
    box-sizing: border-box;

    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &.mini {
    .table-hd {
      height: 36px;
      .table-tr {
        height: 100%;
        .table-th {
          font-size: 14px;
          color: #c4d6ff;
          letter-spacing: 0;
          text-align: center;
          line-height: 20px;
          font-weight: 500;
        }
      }
    }
    .table-bd {
      .table-tr {
        height: 36px;
      }
    }
    .table-pagination {
      height: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
