<template>
  <div class="seeMore">
    <div class="seeMore-center">
      <div class="report">
        <Box>
          <div style="width: 100%">
            <div @click="back" class="back-button" style="float: right"></div>
            <div class="body">
              <el-form ref="edit_form" class="linear-form vertical edit-form">
                <div style="width: 100%" class="aa">
                  <el-form-item label="任务名称" prop="taskTitle">
                    <el-input
                      v-model="list.taskTitle"
                      readonly
                    />
                  </el-form-item>
                  <el-form-item label="任务类型" prop="cate">
                    <el-input
                      readonly
                      v-model="list.taskCateName"
                    />
                  </el-form-item>

                  <el-form-item label="场景类型" prop="name">
                    <el-input
                      v-model="list.taskTypeName"
                      readonly
                    />
                  </el-form-item>

                  <el-form-item label="配置AI" prop="reportApplyUser">
                    <el-input
                      v-model="list.ai"
                      readonly
                    />
                  </el-form-item>

                  <el-form-item label="飞行时间" prop="addtime">
                    <el-input
                      readonly
                      v-model="time"
                    />
                  </el-form-item>

                  <!-- <el-form-item label="作业详细时间" prop="addtime">
                    <el-input
                      v-model="list.gzTimes"
                      readonly
                    />
                  </el-form-item> -->
                  <!-- <div style="width: 100%;" class="bottombox"> -->
                    <!-- <div  class="left"> -->
                      <el-form-item label="作业位置" prop="addtime">
                        <el-input
                          v-model="location"
                          readonly
                        />
                      </el-form-item>
                      <div class="approval">
                        <el-form-item label="当前审批人" prop="addtime">
                          <el-input
                            v-model="list.currentApprover"
                            readonly
                          />
                        </el-form-item>
                        <el-form-item v-if="list.approvalRemark" label="审批不通过原因" prop="addtime">
                          <el-input
                            v-model="list.approvalRemark"
                            readonly
                          />
                        </el-form-item>
                      </div>

                      <el-form-item label="需求描述" prop="addtime">
                    <el-input
                      v-model="list.remark"
                      readonly
                      type="textarea"
                      :autosize="{ minRows: 4, maxRows: 6}"
                    />
                  </el-form-item>

                      <el-form-item label="总流程状态" prop="taskTitle">
                        <div class="bigbox">
                          <div
                            class="processbox"
                            v-for="(items, index) in arry2"
                            :key="index"
                          >
                            <div class="processone">
                              <el-tooltip
                                class="item"
                                effect="dark"
                                :content="items.approver"
                                placement="top"
                              >
                                <div class="processonebox">
                                  <span>{{ items.approver }}</span
                                  ><span v-if="items.name"
                                    >-{{ items.name }}</span
                                  >
                                </div>
                              </el-tooltip>

                              <p>{{ items.papplyStatus }}</p>
                              <div class="end"></div>
                            </div>
                            <div
                              class="processfirsbox"
                              v-if="index != arry2.length - 1"
                            >
                              <div>
                                <div class="wire">
                                  <div class="triangle"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </el-form-item>
                    <!-- </div> -->

                  
                  </div>
                <!-- </div> -->
              </el-form>
            </div>
          </div>
        </Box>
      </div>
      <div class="Side">
        <div v-if="list.papplyStatus === 0" class="box" @click="dialogVisible = true" ><span class="iconfont icon-shenpi" ></span></div>
      </div>
    </div>

    <el-dialog
      title="温馨提示"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="384px"
      class="taskapprove"
    >
      <el-dialog
        width="484px"
        title="审批不通过原因"
        :visible.sync="innerVisible"
        :close-on-click-modal="false"
        append-to-body
      >
        <div style="color: #cce8ff; text-align: center; font-size: 20px">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入审批不通过原因"
            v-model="reasonFailure"
          ></el-input>
        </div>
        <div
          class="button"
          style="
            display: flex;
            justify-content: space-between;
            padding: 27px 0 10px 0;
          "
        >
          <div
            @click="
              () => {
                (this.innerVisible = false), (this.reasonFailure = '');
              }
            "
            class="linear-btn"
          >
            取消
          </div>
          <div @click="pass(2)" class="linear-btn">确定</div>
        </div>
      </el-dialog>
      <div style="color: #cce8ff; text-align: center; font-size: 20px">
        审批需求清单是否通过？
      </div>
      <div class="button">
        <div class="linear-btn" @click="pass(1)">审批通过</div>
        <div class="linear-btn" @click="innerVisible = true">审批不通过</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Box from "@/components/platform/common/box";
import Table from "@/components/common/table";
import API from "@/api";
export default {
  components: {
    Box,
    Table,
  },
  data() {
    return {
      innerVisible: false, // 处理需求内层弹框
      reasonFailure: "", // 处理需求不通过原因
      dialogVisible: false,
      list: {
        taskTitle: null,
        taskCateName: null,
        taskTypeName: null,
        ais: null,
        time: null,
        location: null,
        providePersonCount: null,
        drones: null,
        mounts: null,
        provideCarCount: null,
        workDayCount: null,
        remark: null,
        gzTimes: null,
        mountType: [],
        deviceType: [],
        process: [],
      },
      id: null,
      time: null,
      location: null,
      mount: [],
      mounts: "",
      drone: [],
      drones: "",
      arry2: [],
      spname: null,
    };
  },
  methods: {
    async getdata() {
      let res = await API.TASK.getdetails({
        id: this.id
      })
      this.list = res.data
      const { data } = res
      this.time = `${data.startedAt} — ${data.endAt}`
      this.location = `${data.origin} — ${data.destination}`
      this.list.gzTimes = data.gzTimes ? data.gzTime[0].start  + '—' + data.gzTime[0].end : null
      //处理挂载信息
      this.list.mountType.forEach((item, index) => {
        let data = `${item.name}-${item.count}个`;
        this.mount.push(data);
      });
      this.mounts = this.mount.join(",");
      //处理无人机信息
      this.list.deviceType.forEach((item, index) => {
        let data = `${item.name}-${item.count}架`;
        this.drone.push(data);
      });
      this.drones = this.drone.join(",");

      //总流程状态信息处理
      this.list.process.forEach((item, index) => {
        switch (item.papplyStatus) {
          case -1:
            item.papplyStatus = "发起审批";
            break;
          case 0:
            item.papplyStatus = "待审批";
            break;
          case 1:
            item.papplyStatus = "审批通过";
            break;
          case 2:
            item.papplyStatus = "审批不通过";
        }
        this.arry2.push(item);

        // 回显当前审批人
        if (this.arry2.length > 2) {
          this.spname = this.arry2[this.arry2.length - 1].approver;
        }
      });
    },

    //审批事件 1为通过，2为不通过
    async pass(num) {
      if (num == 2 && !this.reasonFailure) {
        this.$message.warning("请输入审批不通过原因");
      } else {
        let obj = {
          id: this.id,
          status: num,
        };
        if (this.reasonFailure) {
          obj.approvalRemark = this.reasonFailure;
        }
        try {
          const res = await API.TASK.EditPApplyStatus(obj);
          if (res.status == 1) {
            if (num == 1) {
              this.$el_message("审批通过");
              
            } else {
              this.$message.error("审批不通过");
            }
            this.dialogVisible = false;
            this.innerVisible = false;
            this.reasonFailure = "";
            this.back()
          } else {
            this.$el_message(res.message, null, "error");
          }
        } catch (e) {
          console.error(e);
        }
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
  mounted() {},
  created() {
    this.id = this.$route.params.id;
    this.getdata();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-textarea__inner {
    opacity: 0.8;
    background-color: #122a53;
    border: 1px solid #488cff;
    color: #9fc4ff;
  }
}
.seeMore {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
  &-center {
    height: 991px;
    padding-top: 35px;
    display: flex;
    .report {
      width: 1288px;
      .header {
        padding-top: 4px;
        height: 36px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .title {
          width: 528px;
          height: 28px;
          background: url(~@/assets/platform/device/header.png) no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            color: #ffffff;
            font-size: 18px;
          }
        }
      }
      .body {
        padding: 24px 73px 0;
        height: 100%;
        // overflow: auto;

        .color {
          color: #b6d6ff;
          font-size: 18px;
        }
        .image {
          width: 80px;
          height: 70px;
        }
      }
    }
    .Side {
      padding-top: 58px;
      padding-left: 14px;
      .box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        width: 52px;
        height: 52px;
        border: 1px solid #009aff;
        .iconfont {
          cursor: pointer;
          font-size: 24px;
          color: #9fc4ff;
        }
      }
    }
  }
}
::v-deep {
  .bottombox {
    display: flex;
    justify-content: space-between;
    .left {
      width: calc(50% - 6px) !important;
      .el-form-item {
        width: calc(100% - 6px) !important;
      }
    }
  }
}
.bigbox {
  display: flex;
  .processbox {
    display: flex;
    .processone {
      margin-top: 12px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .processonebox {
        height: 40px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 40px;
        box-sizing: border-box;
        padding-left: 47px;
        color: #4896ff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: url(~@/assets/platform/message/task/details/process.png)
          no-repeat;
      }
      p {
        width: 100%;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9fc4ff;
        line-height: 20px;
        text-align: center;
        margin: 8px 0;
      }
      .end {
        width: 100px;
        height: 1px;
        border-bottom: 1px solid;
        border-image: linear-gradient(
            270deg,
            rgba(25, 136, 234, 0),
            rgba(25, 136, 234, 1),
            rgba(25, 136, 234, 0)
          )
          1 1;
      }
    }
    .processfirsbox {
      // position: absolute;
      padding-top: 30px;
      margin: 0 10px;
      .wire {
        width: 90px;
        height: 1px;
        border-bottom: 1px solid;
        border-image: linear-gradient(
            270deg,
            rgba(42, 180, 255, 0.5),
            rgba(62, 154, 255, 0)
          )
          1 1;
        .triangle {
          width: 7px;
          height: 13px;
          background: linear-gradient(
            360deg,
            rgba(3, 46, 126, 0) 0%,
            #1785e7 100%
          );
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep {
  .edit-form {
    .aa {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .el-textarea__inner{
        min-height: 112px !important;
      }
      .el-form-item {
        width: calc(50% - 6px);
      }
      .approval{
        width: 50%;
        .el-form-item{
          width: calc(100% - 6px) !important;
        }
      }
    }
    .linear-fields {
      width: 100%;
      margin-bottom: 20px;
      .el-form-item {
        width: 100%;
      }
    }
  }
  .el-dialog {
    .el-dialog__body {
      padding: 28px 20px 0px 20px;
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }
  
}
</style>
<style lang="scss">
.report .cpt-service-table .table-bd .table-tr {
  height: 80px;
}

.taskapprove {
  .button {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    padding: 28px 55px;
    // padding: 28px 55px 0 55px;
  }
}
.back-button {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-image: url(~@/assets/platform/message/task/details/closure.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
