<template>
  <div class="cpt-service-box">
    <div class="service-box-inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@mixin corner(
  $top: auto,
  $right: auto,
  $bottom: auto,
  $left: auto,
  $border-top-width: 3px,
  $border-right-width: 3px,
  $border-bottom-width: 3px,
  $border-left-width: 3px
) {
  width: 15px;
  height: 15px;
  border: 3px solid #6da4ff;
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  border-top-width: $border-top-width;
  border-right-width: $border-right-width;
  border-bottom-width: $border-bottom-width;
  border-left-width: $border-left-width;
}
.cpt-service-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1px;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(
    180deg,
    rgba(0, 57, 118, 0.5) 0%,
    rgba(3, 20, 54, 0.5) 100%
  );
  &::before {
    content: " ";
    display: block;
    @include corner(0, auto, auto, 0, 3px, 0, 0, 3px);
  }
  &::after {
    content: " ";
    display: block;
    @include corner(0, 0, auto, auto, 3px, 3px, 0, 0);
  }
  .service-box-inner {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 24px;
    border: 1px solid #1988ea;
    background: center -1px url(~@/assets/platform/box-border-top.png) no-repeat,
      center calc(100% + 1px) url(~@/assets/platform/box-border-bottom.png)
        no-repeat;
    overflow: hidden;
    &::before {
      content: " ";
      display: block;
      @include corner(auto, auto, 0, 0, 0, 0, 3px, 3px);
    }
    &::after {
      content: " ";
      display: block;
      @include corner(auto, 0, 0, auto, 0px, 3px, 3px, 0);
    }
  }
}
</style>